<style lang="scss">
.martkplatz-veranstaltungenkulinarik {
  .head-row-text-normal {
    .text-normal {
      display: inline-block;
      text-transform: none !important;
    }
  }

  h2 {
    margin-top: 0;
  }
  ul {
    margin: 10px 0 !important;
    li {
      margin: 5px 0 !important;
    }
  }
  .custom-link {
    color: $blue-dark !important;
    font-weight: 500;
  }
  .preis-box {
    padding: 5px 0;
    margin-bottom: 10px;

    span {
      display: inline-block;
      font-style: italic;
      padding: 8px 10px;
      border-top: 1px solid #eee;
      border-bottom: 3px solid #eee;
      strong {
        display: inline-block;

        font-weight: 400;
        margin-left: 5px;
      }
    }
  }
  .portfolio-box {
    margin-top: 50px;
    [class*='col-'] {
      justify-items: center;
      align-self: center;
    }
  }
  .bild-box {
    img {
      display: block;
      margin: 0 auto;
    }
  }
  .text-center-small {
    @media (max-width: 992px) {
      text-align: center !important;
    }
  }
  .content-link-mehrinfo {
    color: #fff !important;
  }
  .format-box {
    margin-bottom: 20px;
    .col-lg-5,
    .col-lg-3 {
      justify-content: center;
      align-self: center;
    }
    img {
      display: block;
      margin: 0 auto;
    }
    .tabelle-box {
      .headline {
        text-transform: none;
        strong {
          display: block;
          text-transform: uppercase;
        }
      }
      .head-row {
        font-size: 80%;
      }
    }
  }
  .alignItems {
    [class*='col-'] {
      justify-content: center;
      align-self: center;
    }
  }
  .download-pdf {
    a {
      max-width: 100%;
    }
  }
}
.collapsible-container {
  a {
    color: $blue !important;
  }
  img {
    margin: 15px auto;
    max-width: 100%;
    display: block;
  }
  .info-box {
    padding: 10px;
    background: #eee;
    margin: 20px 0;
  }
  .tipp-box {
    margin-bottom: 20px;
    padding: 10px;
    background: $blue-darker;
    color: #fff;
    strong {
      text-transform: uppercase;
    }
    a {
      color: #fff;
      font-weight: 500;
    }
  }
}
</style>

<template>
  <div class="martkplatz-veranstaltungenkulinarik content container">
    <h1>Veranstaltungen &amp; Kulinarik</h1>
    <p class="intro-txt">Bewerben Sie die besten Veranstaltungen und kulinarischen Gaumenfreuden punktgenau in den neun steirischen Regionalausgaben (außer Graz und Graz Umgebung) und den sieben Kärntner Regionalausgaben (außer Klagenfurt).</p>
    <div class="intro-img">
      <img src="@/assets/img/img-content/intro-img/veranstaltung-kulinarik.jpg" alt="Veranstaltungen und Kulinarik" class="img-fluid" />
    </div>

    <h2>Individuelle Werbelösungen</h2>
    <br />
    <div class="row format-box">
      <div class="col-lg-6 order-lg-2 justify-content-center align-self-center">
        <div class="tabelle-box">
          <div class="headline">
            <strong>Erscheinungstermine</strong>
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-4 text-align-center">Ausgabe</div>
            <div class="col-lg-4 text-align-center">Erscheinungstag</div>
            <div class="col-lg-4 text-align-center">Anzeigenschluss</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Ausgabe</div>
            <div class="col-lg-4 col-6 text-align-center">Steiermark &amp; Kärnten</div>
            <div class="col-6 text-align-left visible-small">Erscheinungstag</div>
            <div class="col-lg-4 col-6 text-align-center">Mittwoch</div>
            <div class="col-6 text-align-left visible-small">Anzeigenschluss</div>
            <div class="col-lg-4 col-6 text-align-center">Montag 12:00 Uhr</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Ausgabe</div>
            <div class="col-lg-4 col-6 text-align-center">Steiermark</div>
            <div class="col-6 text-align-left visible-small">Erscheinungstag</div>
            <div class="col-lg-4 col-6 text-align-center">Sonntag</div>
            <div class="col-6 text-align-left visible-small">Anzeigenschluss</div>
            <div class="col-lg-4 col-6 text-align-center">Mittwoch 14:00 Uhr</div>
          </div>
        </div>
        <!-- -->
        <div class="tabelle-box">
          <div class="headline">
            <strong>Formate &amp; Tarife</strong>
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-6 text-align-center">Format</div>
            <div class="col-lg-6 text-align-center">Tarif</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Format</div>
            <div class="col-lg-6 col-6 text-align-center">62 x 35 mm</div>
            <div class="col-6 text-align-left visible-small">Tarif</div>
            <div class="col-lg-6 col-6 text-align-center">48,00*</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Format</div>
            <div class="col-lg-6 col-6 text-align-center">62 x 72 mm</div>
            <div class="col-6 text-align-left visible-small">Tarif</div>
            <div class="col-lg-6 col-6 text-align-center">80,00*</div>
          </div>
          <div class="info-txt-tabelle">*Tarif gültig bei <strong>Selbsterfassung</strong> über <strong>Kleine Zeitung Web-Store</strong>. Bei Eingabe durch das Auftrags-Management wird eine <strong>Service-Pauschale</strong> von <strong>50,00 pro Sujet</strong> verrechnet. Fixpreis, keine weiteren Rabatte mehr möglich, keine Verrechnung über das Auktionsguthaben</div>
        </div>
      </div>
      <div class="col-lg-6 justify-content-center align-self-center">
        <img src="@/assets/img/img-content/marktplaetze/veranstaltung-kulinarik-v2.png" class="img-fluid" />
      </div>
    </div>
    <div class="info-txt-preis">Alle Preise verstehen sich in Euro, exklusive 20 % Umsatzsteuer.</div>

    <div class="download-pdf">
      <div class="line"><span>DOWNLOAD</span></div>
      <a href="../../pdf/marktplaetze/Veranstaltungen-und-Kulinarik-07-2021.pdf" target="_blank">
        <div class="row">
          <div class="col-8 justify-content-center align-self-center">
            Tarif Veranstaltungen &amp; Kulinarik
          </div>
          <div class="col-4 justify-content-end align-self-center text-right">
            <span>251 kb // PDF</span>
          </div>
        </div>
      </a>
    </div>
    <!--<div class="row">
      <div class="col-lg-6">
        <div class="download-pdf">
          <div class="line"><span>Download</span></div>
          <a href="../../pdf/marktplaetze/0246-2101-Veranstaltungen-Kulinarik-Flyer-web-STMK.pdf" target="_blank">
            <div class="row">
              <div class="col-8 justify-content-center align-self-center">
                Tarif Steiermark
              </div>
              <div class="col-4 justify-content-end align-self-center text-right">
                <span>288 kb // PDF</span>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="download-pdf">
          <div class="line"><span>Download</span></div>
          <a href="../../pdf/marktplaetze/0246-2101-Veranstaltungen-Kulinarik-Flyer-web-KTN.pdf" target="_blank">
            <div class="row">
              <div class="col-8 justify-content-center align-self-center">
                Tarif Kärnten
              </div>
              <div class="col-4 justify-content-end align-self-center text-right">
                <span>305 kb // PDF</span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>-->
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  name: 'Wohnen',
  components: {
    //Modal,
    //TabGroup,
    //Tab,
  },
});
</script>
